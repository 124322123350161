import { useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Slide, Box, Grid, Grow } from '@mui/material'
import { DateTime, Duration } from 'luxon'

import { API } from '../helpers/API'
import { spaceThousands } from '../helpers/text'
import { ContextType } from '../views/Admin'
import { AdminContainer } from './AdminContainer'
import { TableInfo } from './TableInfo'

export function AdminGlobal({ setMapInfos }) {
  const [records, setRecords] = useState([])
  const [users, setUsers] = useState([])
  const params = useParams()
  const [unverifiedVehicles, setPendingVehicles] = useState([])
  const totalDistance = useMemo(() => {
    return records.reduce((sum, r: any) => r.raw_distance + sum, 0)
  }, [records])
  const [displayPanel, setDisplayPanel] = useState(false)
  const [hide, setHide] = useState(false)
  const navigate = useNavigate()

  const unverifiedVehicleHeaders = [
    { key: 'id', label: 'Id' },
    { key: 'user', label: 'User' },
    { key: 'createdAt', label: 'Date' },
  ]
  const statsHeaders = [
    { key: 'users', label: 'Users' },
    { key: 'records', label: 'Records' },
    { key: 'distance', label: 'Distance' },
  ]
  const usersHeaders = [
    { key: 'username', label: 'Username' },
    { key: 'totalRecords', label: 'Records' },
    { key: 'totalDistance', label: 'Distance' },
    { key: 'totalTime', label: 'Time' },
    { key: 'xp', label: 'XP' },
  ]
  const recordHeaders = [
    { key: 'date', label: 'Date' },
    { key: 'username', label: 'User' },
    { key: 'duration', label: 'Duration' },
    { key: 'distance', label: 'Distance' },
  ]

  useEffect(() => {
    Promise.all([
      API.getRecords().then(data =>
        setRecords(
          data.records.map(r => ({
            ...r,
            date: DateTime.fromISO(r.date).toFormat("dd/MM HH'h'mm"),
            duration: Duration.fromObject({ minutes: r.duration }).toFormat(
              "h'h' mm'min'",
            ),
            raw_distance: r.distance,
            distance: `${spaceThousands(Number((r.distance / 1000).toFixed(2)))}km`,
          })),
        ),
      ),
      API.getUsers().then(data => {
        setPendingVehicles(
          data.users
            .map(user => {
              return user.vehicles
                .filter(v => v.needQualityVerification)
                .map(v => ({
                  id: v.id.slice(0, 8),
                  user: user.username,
                  createdAt: DateTime.fromISO(v.createdAt).toFormat('yyyy-MM-dd'),
                }))
            })
            .flat(),
        )
        setUsers(
          data.users.map(r => ({
            ...r,
            // date: DateTime.fromISO(r.date).toFormat('dd/MM HH\'h\'mm'),
            xp: spaceThousands(r.xp, ','),
            totalTime: Duration.fromObject({ minutes: r.totalTime }).toFormat("h'h'"),
            totalDistance: `${spaceThousands(Math.round(r.totalDistance / 1000), ',')}km`,
          })),
        )
      }),
    ]).then(() => setDisplayPanel(true))
  }, [])

  function changePage(state: ContextType) {
    setHide(true)
    navigate(state.tileId ?? 'noop', {
      state: {
        ...state,
        padding: {
          top: 16 * 2 + 55 + 16,
          bottom: 32,
          left: 450 + 16,
          right: 16 + 29 + 10,
        },
      },
      replace: !!params.tileId,
    })
    // setTimeout(() => {
    //   navigate(state.tileId ?? 'noop', { state })
    // }, 250)
  }

  useEffect(() => {
    if (!params.tileId) {
      setMapInfos({})
    }
  }, [params, setMapInfos])

  return (
    <AdminContainer>
      <Slide direction="right" in={true} unmountOnExit>
        <Box m={2}>
          <Grid container spacing={2}>
            <Grow in={displayPanel} style={{ transformOrigin: '50% 0%' }}>
              <Grid item xs={12} md={12}>
                <TableInfo
                  headers={statsHeaders}
                  values={[
                    {
                      users: users.length,
                      records: records.length,
                      distance: `${spaceThousands(
                        Math.round(totalDistance / 1000),
                        ',',
                      )}km`,
                    },
                  ]}
                  onClick={() => {}}
                  noSelect
                />
              </Grid>
            </Grow>
            <Grow timeout={250} in={displayPanel} style={{ transformOrigin: '50% 0%' }}>
              <Grid item xs={12} md={12}>
                <TableInfo
                  title={`Not verified vehicles (${unverifiedVehicles.length})`}
                  headers={unverifiedVehicleHeaders}
                  values={unverifiedVehicles}
                  onClick={e => {
                    changePage({
                      tileId: e.id,
                      customQuality: e.quality,
                    })
                  }}
                />
              </Grid>
            </Grow>
            <Grow timeout={500} in={displayPanel} style={{ transformOrigin: '50% 0%' }}>
              <Grid item xs={12} md={12}>
                <TableInfo
                  title={`Users (${users.length})`}
                  headers={usersHeaders}
                  values={users}
                  onClick={e => {
                    changePage({
                      tileId: e.id,
                      customQuality: {
                        min: 1,
                        max: 100,
                      },
                    })
                  }}
                />
              </Grid>
            </Grow>
            <Grow timeout={750} in={displayPanel} style={{ transformOrigin: '50% 0%' }}>
              <Grid item xs={12} md={12}>
                <TableInfo
                  title={`Records (${records.length})`}
                  headers={recordHeaders}
                  values={records}
                  onClick={e => {
                    changePage({
                      tileId: e.id,
                      customQuality: e.quality,
                    })
                  }}
                />
              </Grid>
            </Grow>
          </Grid>
        </Box>
      </Slide>
    </AdminContainer>
  )
}
