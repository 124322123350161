import ky from 'ky'

import { config } from '../config'

const prefixUrl = config.baseUrl

function getAccessToken() {
  try {
    return JSON.parse(localStorage.getItem('userInfos') || '').accessToken
  } catch (e) {
    return ''
  }
}

const api = ky.extend({
  prefixUrl,
  hooks: {
    beforeRequest: [
      function (req) {
        try {
          const tokenKey = req.url.endsWith('refreshToken')
            ? 'refreshToken'
            : 'accessToken'
          const userInfos = JSON.parse(localStorage.getItem('userInfos') || '')
          req.headers.set('authorization', `Bearer ${userInfos[tokenKey]}`)
        } catch (e) {}
      },
    ],
  },
})

export const API = {
  prefixUrl,

  async isLogged() {
    const data = await api.get('refreshToken').json<any>()

    localStorage.setItem('userInfos', JSON.stringify(data))
  },
  async signin({ email, password }: { email: string; password: string }) {
    const data = await api.post('signin', { json: { email, password } }).json<any>()
    localStorage.setItem('userInfos', JSON.stringify(data))
  },
  async getRecords() {
    return api.get('records').json<any>()
  },
  async getUsers() {
    return api.get('users').json<any>()
  },
  getTileUrl(tileId: String) {
    return `${prefixUrl}tiles/infos.json?id=${tileId}&token=${getAccessToken()}`
  },
}
