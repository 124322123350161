import { CSSProperties, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/CloseRounded'
import {
  Box,
  Button,
  Hidden,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Color from 'color'

import { Map } from '../components/Map'

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  map: {
    flexGrow: 1,
  },
  dialogContainer: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  dialog: {
    backdropFilter: 'blur(10px)',
    borderRadius: 8,
  },
  banner: {
    position: 'relative',
    zIndex: 1002,
    boxShadow: '0 0 18px 0 rgba(0,0,0,.3)',
    padding: 10,
    paddingLeft: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
  },
  banner2: {
    position: 'relative',
    zIndex: 1002,
    boxShadow: '0 0 18px 0 rgba(0,0,0,.3)',
    padding: 10,
    paddingLeft: 14,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    userSelect: 'none',
    flexDirection: 'column',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  bannerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  bannerApp: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 14,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsContainer2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  linkDisabled: {
    cursor: 'not-allowed',
    opacity: 0.45,
  },
  icon: {
    width: 36,
    height: 36,
    borderRadius: 8,
    backgroundImage: 'url(/appicon.webp)',
    backgroundSize: 'cover',
  },
  link: {
    width: 150,
    display: 'flex',
    marginLeft: 5,
  },
  linkImg: {
    width: '100%',
  },
  linkInfo: {
    fontSize: 14,
    marginRight: 5,
  },
  linkInfo2: {
    fontSize: 12,
    marginTop: 5,
  },
  appname: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
    cursor: 'default',
  },
}

export function Home() {
  const [displayBanner, setDisplayBanner] = useState(false)
  const [displayDialog, setDisplayDialog] = useState(false)

  const theme = useTheme()

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [displayBanner])

  return (
    <div style={styles.container}>
      <Map style={styles.map} hash />
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          bottom: 10,
          left: '50%',
          transform: 'translate(-50%, 0)',
          zIndex: 9,
        }}
      >
        <Box
          p={2}
          pt={1}
          pb={1}
          sx={{ display: 'flex' }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="caption"
            display="block"
            sx={{ fontStyle: 'italic' }}
            noWrap
          >
            Bon état
          </Typography>
          <Box
            ml={1}
            mr={1}
            sx={{ display: 'flex' }}
            justifyContent="center"
            alignItems="center"
          >
            <div
              style={{
                width: 20,
                backgroundColor: '#000',
                height: 6,
                marginRight: 3,
                border: '1px solid rgba(255, 255, 255, .5)',
              }}
            ></div>
            <div
              style={{
                width: 20,
                backgroundColor: '#ffa529',
                height: 6,
                marginRight: 3,
                border: '1px solid rgba(255, 255, 255, .5)',
              }}
            ></div>
            <div
              style={{
                width: 20,
                backgroundColor: '#FE721C',
                height: 6,
                marginRight: 3,
                border: '1px solid rgba(255, 255, 255, .5)',
              }}
            ></div>
            <div
              style={{
                width: 20,
                backgroundColor: '#ff0505',
                height: 6,
                border: '1px solid rgba(255, 255, 255, .5)',
              }}
            ></div>
          </Box>
          <Typography
            variant="caption"
            display="block"
            sx={{ fontStyle: 'italic' }}
            noWrap
          >
            Très dégradé
          </Typography>
        </Box>
      </Paper>
      {displayDialog ? (
        <div style={styles.dialogContainer} onClick={() => setDisplayDialog(false)}>
          <Paper
            style={{
              ...styles.dialog,
              backgroundColor: Color(theme.palette.background.paper).fade(0.2).string(),
            }}
          >
            <Box
              p={2}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <p>
                Abonnez-vous pour recevoir une notification quand l'application sera
                disponible !
              </p>
              <Box sx={{ display: 'flex' }} justifyContent="center" alignItems="center">
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="EMAIL"
                  margin="dense"
                  style={{ fontWeight: 900, margin: 0 }}
                />
                <Box ml={1}>
                  <Button variant="contained" disableElevation sx={{ height: 56 }}>
                    S'inscrire
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </div>
      ) : null}
      {displayBanner ? (
        <>
          <Hidden mdDown={true}>
            <Box style={styles.banner}>
              <div style={styles.bannerLeft}>
                <CloseIcon
                  fontSize="medium"
                  style={styles.closeIcon}
                  onClick={() => {
                    setDisplayBanner(false)
                    window.dispatchEvent(new Event('resize'))
                  }}
                />
                <div style={styles.bannerApp}>
                  <div style={styles.icon} />
                  <span style={styles.appname}>Hit the road</span>
                </div>
              </div>
              <div style={styles.buttonsContainer}>
                <span style={styles.linkInfo}>
                  Application iOS en cours de développement
                </span>
                <span style={{ ...styles.link, ...styles.linkDisabled }}>
                  <img
                    style={styles.linkImg}
                    src={require('../assets/app_store.webp')}
                    alt=""
                  />
                </span>
                <a
                  style={styles.link}
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.hittheroad&referrer=website"
                  rel="noopener nofollow noreferrer"
                >
                  <img
                    style={styles.linkImg}
                    src={require('../assets/google_play.png')}
                    alt=""
                  />
                </a>
              </div>
            </Box>
          </Hidden>
          <Hidden mdUp={true}>
            <Box style={styles.banner2}>
              <div style={styles.bannerLeft}>
                <CloseIcon
                  fontSize="medium"
                  style={styles.closeIcon}
                  onClick={() => {
                    setDisplayBanner(false)
                    window.dispatchEvent(new Event('resize'))
                  }}
                />
                <div style={styles.bannerApp}>
                  <div style={styles.icon} />
                  <span style={styles.appname}>Hit the road</span>
                </div>
              </div>
              <div style={styles.buttonsContainer2}>
                <div style={styles.buttonsContainer}>
                  <span style={{ ...styles.link, ...styles.linkDisabled }}>
                    <img
                      style={styles.linkImg}
                      src={require('../assets/app_store.webp')}
                      alt=""
                    />
                  </span>
                  <a
                    style={styles.link}
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.hittheroad&referrer=website"
                    rel="noopener nofollow noreferrer"
                  >
                    <img
                      style={styles.linkImg}
                      src={require('../assets/google_play.png')}
                      alt=""
                    />
                  </a>
                </div>
                <span style={styles.linkInfo2}>
                  Application iOS en cours de développement
                </span>
              </div>
            </Box>
          </Hidden>
        </>
      ) : null}
    </div>
  )
}
