import mapboxgl from 'mapbox-gl'

export type RoadType =
  | 'motorway'
  | 'trunk'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'trunk_link'
  | 'motorway_link'
  | 'primary_link'
  | 'secondary_link'
  | 'tertiary_link'
  | 'living_street'
  | 'residential'
  | 'unclassified'
  | 'service'

export const LayerSpecs: {
  id: string
  highways: RoadType[]
  width: mapboxgl.Expression | number
  minzoom?: number
}[] = [
  {
    id: 'service',
    highways: ['service'],
    width: ['interpolate', ['exponential', 1.5], ['zoom'], 14, 1, 18, 10],
  },
  {
    id: 'road',
    highways: [
      'living_street',
      'residential',
      'unclassified',
      'trunk_link',
      'motorway_link',
      'primary_link',
      'secondary_link',
      'tertiary_link',
    ],
    width: ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 18, 20],
  },
  {
    id: 'secondary_tertiary',
    highways: ['secondary', 'tertiary'],
    width: ['interpolate', ['exponential', 1.5], ['zoom'], 4, 1.5, 18, 20],
    // width: ['interpolate', ['exponential', 1.5], ['zoom'], 3, 0.8, 18, 26],
  },
  {
    id: 'primary',
    highways: ['primary'],
    width: ['interpolate', ['exponential', 1.5], ['zoom'], 4, 1.5, 18, 20],
    // width: ['interpolate', ['exponential', 1.5], ['zoom'], 3, 0.8, 18, 28],
  },
  {
    id: 'motorway',
    highways: ['motorway', 'trunk'],
    width: ['interpolate', ['exponential', 1.5], ['zoom'], 4, 1.5, 18, 20],
    // width: ['interpolate', ['exponential', 1.5], ['zoom'], 3, 0.8, 18, 30],
  },
]

// 'line-width': [
//   'interpolate',
//   ['exponential', 1.5],
//   ['zoom'],
//   0,
//   0,
//   4,
//   0.75,
//   6,
//   1.25,
//   10,
//   1.5,
//   14,
//   2,
//   16,
//   8,
//   18,
//   20,
// ],
