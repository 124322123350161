import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Grid, Grow, Paper } from '@mui/material'

import { ContextType, useMapInfos } from '../views/Admin'
import { AdminContainer } from './AdminContainer'

export function AdminUser() {
  const location = useLocation()
  const { setMapInfos } = useMapInfos()

  useEffect(() => {
    setMapInfos(location.state as ContextType)
  }, [location.state, setMapInfos])

  return (
    <AdminContainer left={450 - 16}>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grow in={true} style={{ transformOrigin: '50% 0%' }}>
            <Grid item xs={12} md={12}>
              <Paper elevation={7}>
                <Box p={2}>Infos</Box>
              </Paper>
            </Grid>
          </Grow>
        </Grid>
      </Box>
    </AdminContainer>
  )
}
