/* eslint-disable max-lines */
import { useRef, useEffect, useState, CSSProperties, useCallback } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import Color from 'color'
import ky from 'ky'
import {
  type LngLat,
  type Map as MapMB,
  type VectorSourceImpl,
  type PaddingOptions,
  type MapboxOptions,
} from 'mapbox-gl'
import SunCalc from 'suncalc'

// eslint-disable-next-line import/no-webpack-loader-syntax, import/order
import mapboxgl from '!mapbox-gl'

import 'mapbox-gl/dist/mapbox-gl.css'
import { config } from '../config'
import { API } from '../helpers/API'
import { LayerSpecs } from '../helpers/Map'
import { useRouting } from '../helpers/useRouting'
import { Geocoder } from './Geocoder'
import { HidePannel, WaysPanel } from './RoutingPanel'

//

const HEATMAP_COEF = 0.5

type GeoJsonProperties = { [name: string]: any } | null

mapboxgl.accessToken = config.apiKey

function addButton(
  iconLabel: string,
  titles: { checked: string; unchecked: string },
  onClick: (this: HTMLElement) => void = () => {},
  defaultChecked = true,
) {
  const button = document.createElement('button')
  button.style.color = '#333333'
  const icon = document.createElement('span')
  icon.classList.add(defaultChecked ? 'material-icons' : 'material-icons-outlined')
  icon.style.fontSize = '18px'
  icon.style.userSelect = 'none'
  icon.textContent = iconLabel
  button.dataset.checked = defaultChecked.toString()
  button.title = titles[defaultChecked ? 'checked' : 'unchecked']

  button.addEventListener('click', onClick)
  button.addEventListener('click', () => {
    if (button.dataset.checked === 'true') {
      icon.classList.remove('material-icons')
      icon.classList.add('material-icons-outlined')
      button.dataset.checked = 'false'
      button.title = titles.unchecked
    } else {
      icon.classList.remove('material-icons-outlined')
      icon.classList.add('material-icons')
      button.dataset.checked = 'true'
      button.title = titles.checked
    }
  })

  button.append(icon)
  document.querySelector('.mapboxgl-ctrl-group')?.append(button)
}

function getSunPosition(center: LngLat, date?: Date) {
  var sunPos = SunCalc.getPosition(date || new Date(), center.lat, center.lng)
  var sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI
  var sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI
  return [sunAzimuth, sunAltitude]
}

export function Map({
  style,
  tileId,
  quality,
  padding,
  fitBounds = false,
  hash = false,
  isAdmin = false,
  onHover,
}: {
  style?: CSSProperties
  tileId?: string
  quality?: { min: number; max: number }
  padding?: PaddingOptions
  fitBounds?: boolean
  hash?: boolean
  isAdmin?: boolean
  onHover?: (props: GeoJsonProperties) => any
}) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const mapContainer = useRef(null)
  const map = useRef<MapMB>()
  const [loaded, setLoaded] = useState(false)
  const [displayGraph, setDisplayGraph] = useState(false)
  const { addRouting, markerData, markers, geoJson } = useRouting()

  const isIndividual = tileId && tileId !== 'heatmap'

  const addButtons = useCallback((map: MapMB, isAdmin = false) => {
    if (isAdmin) {
      addButton(
        'insert_chart',
        { checked: 'Cacher le graphique', unchecked: 'Afficher le graphique' },
        function () {
          const value = this.dataset.checked === 'true' ? false : true
          setDisplayGraph(value)
        },
        false,
      )
    }
    addButton(
      'label',
      { checked: 'Cacher les labels', unchecked: 'Afficher les labels' },
      function () {
        const value = this.dataset.checked === 'true' ? false : true

        const keys = [
          ...map
            .getStyle()
            .layers.filter(({ id }) => id.includes('label'))
            .map(({ id }) => id),
          'settlement-major-label',
          'settlement-minor-label',
          'road-number-shield',
          'road-label-outdoors',
          'poi-label',
        ]

        if (value) {
          keys.forEach(k => {
            map.setLayoutProperty(k, 'visibility', 'visible')
          })
        } else {
          keys.forEach(k => {
            map.setLayoutProperty(k, 'visibility', 'none')
          })
        }
      },
    )
    addButton(
      'park',
      { checked: 'Cacher la vue satellite', unchecked: 'Afficher la vue satellite' },
      function () {
        const value = this.dataset.checked === 'true' ? false : true

        map.setLayoutProperty('satellite', 'visibility', value ? 'visible' : 'none')
      },
      false,
    )
  }, [])

  function addRecords(map: MapMB, id: string) {
    try {
      if (map.getSource('records')) {
        LayerSpecs.forEach(l => {
          map.removeLayer(l.id)
        })
        map.removeSource('records')
      }
    } catch (e) {}
    try {
      if (map.getSource('placeholder')) {
        map.removeLayer('placeholder')
        map.removeSource('placeholder')
      }
    } catch (e) {}

    if (id === 'heatmap') {
      return
    }

    map.addSource('records', {
      type: 'vector',
      url: API.getTileUrl(id),
    })

    map.addSource('placeholder', {
      type: 'vector',
      url: API.getTileUrl(`${id || 'records'}_placeholder`),
    })
    map.addLayer({
      id: 'placeholder',
      source: 'placeholder',
      'source-layer': 'records',
      minzoom: id ? 0 : 7,
      maxzoom: 10,
      paint: {
        'line-color': Color('white')
          .darken(0.64)
          .mix(Color('hsl(218, 95%, 64%)'), 0.2)
          .string(),
        'line-width': 1.5,
      },
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
      },
      type: 'line',
    })

    for (const layer of LayerSpecs) {
      map.addLayer({
        id: layer.id,
        source: 'records',
        'source-layer': 'records',
        minzoom: isIndividual ? 0 : layer.minzoom ?? 7,
        paint: {
          'line-color': '#000',
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 4, 1],
          'line-width': layer.width,
        },
        layout: {
          'line-cap': ['step', ['zoom'], 'butt', 13, 'round'],
          'line-join': 'round',
          'line-round-limit': 1.5,
        },
        filter: ['all', ['match', ['get', 'highway'], layer.highways, true, false]],
        type: 'line',
      })
      if (onHover) {
        map.on('mousemove', layer.id, async function (e) {
          onHover(map.queryRenderedFeatures(e.point)[0].properties)
        })
      }
    }
  }

  function addHeatmap(map: MapMB, id: string) {
    try {
      if (map.getSource('heatmap')) {
        map.removeLayer('heatmap0')
        map.removeLayer('heatmap1')
        map.removeLayer('heatmap2')
        map.removeSource('heatmap')
      }
    } catch (e) {}

    const isHeatmap = id === 'heatmap'
    if (id === 'records' || isHeatmap) {
      map.addSource('heatmap', {
        type: 'vector',
        url: API.getTileUrl('heatmap'),
      })
      const hideProps = isHeatmap ? {} : { maxzoom: 7 }
      map.addLayer({
        id: 'heatmap0',
        source: 'heatmap',
        'source-layer': 'records',
        ...hideProps,
        paint: {
          'line-color': Color('hsl(218, 95%, 50%)')
            .fade(1 - 0.4 * HEATMAP_COEF)
            .string(),
          'line-width': 5,
          'line-blur': 3,
        },
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        type: 'line',
      })
      map.addLayer({
        id: 'heatmap1',
        source: 'heatmap',
        'source-layer': 'records',
        ...hideProps,
        paint: {
          'line-color': Color('hsl(218, 95%, 64%)')
            .fade(1 - 0.1 * HEATMAP_COEF)
            .string(),
          'line-width': 3,
          'line-blur': 2,
        },
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        type: 'line',
      })
      map.addLayer({
        id: 'heatmap2',
        source: 'heatmap',
        'source-layer': 'records',
        ...hideProps,
        paint: {
          'line-color': Color('hsl(176, 100%, 73%)')
            .fade(1 - 0.25 * HEATMAP_COEF)
            .string(),
          'line-width': 0.5,
        },
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        type: 'line',
      })
    }
  }

  useEffect(() => {
    if (map.current || mapContainer.current === null) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/yoboy/cknc78x0a18w118o1pjpmal6k',
      // style: 'mapbox://styles/yoboy/cknc78x0a18w118o1pjpmal6k/draft',
      // style: 'mapbox://styles/yoboy/clg0yd1wh000601mpucloq47w',
      // style: 'mapbox://styles/yoboy/clg0zogk3004n01p6yl8twfpq',
      center: [-1.702881, 48.100094],
      zoom: 14,
      attributionControl: false,
      hash,
      maxZoom: 15.5,
      projection: {
        name: 'globe',
      },
    } as MapboxOptions) as MapMB

    map.current.addControl(
      new mapboxgl.NavigationControl({
        visualizePitch: true,
      }),
    )
    map.current.addControl(new mapboxgl.ScaleControl())

    map.current.once('load', function () {
      if (!map.current) return

      addButtons(map.current, isAdmin)

      setLoaded(true)

      // Satellite
      map.current.addLayer(
        {
          id: 'satellite',
          source: {
            type: 'raster',
            url: 'mapbox://mapbox.satellite',
            // tileSize: window.devicePixelRatio > 1 ? 256 : 512,
            // minzoom: 18,
          },
          type: 'raster',
          paint: {
            'raster-opacity': 0.35,
          },
          layout: {
            visibility: 'none',
          },
        },
        map.current
          .getStyle()
          .layers.find(
            (l: any) => l.metadata['mapbox:featureComponent'] !== 'land-and-water',
          )?.id,
      )

      function updateRealTime(date?: Date) {
        const sunPos = getSunPosition(map.current!.getCenter(), date)
        // const limit = 90
        // const max = 94
        // const amount = Math.max((sunPos[1] - limit) / (max - limit), 0)
        map.current!.setFog({
          'high-color': 'transparent',
          'space-color': '#010b19',
          'star-intensity': ['interpolate', ['linear'], ['zoom'], 0, 0.4, 10, 0.02],
          'horizon-blend': ['interpolate', ['linear'], ['zoom'], 4, 0.2, 7, 0.1],
          color: [
            'interpolate',
            ['linear'],
            ['zoom'],
            2,
            'rgba(0, 0, 0, 0)',
            14,
            'rgba(0, 0, 0, 0.77)',
          ],
          // color: Color('rgba(225,228,234,.8)').darken(amount).string(),
        })
        // console.log(map.current!.getStyle().layers)
        map.current!.setPaintProperty(
          'terrain',
          'hillshade-illumination-direction',
          Math.min(sunPos[0], 359),
        )
      }

      updateRealTime()
      setInterval(() => {
        updateRealTime()
      }, 10e3)

      map.current.on('dragend', () => {
        updateRealTime()
      })
      map.current.on('zoomend', () => {
        updateRealTime()
      })
      map.current.on('click', function (e) {
        if (e.originalEvent.cancelBubble) {
          return
        }
        if (e.originalEvent.metaKey) {
          const coords = e.lngLat
          window.open(
            `http://maps.google.com/?cbll=${coords.lat},${coords.lng}&cbp=12,20.09,,0,5&layer=c`,
          )
        }
      })
    })
  }, [addButtons, hash, isAdmin, mapContainer])

  useEffect(() => {
    if (!map.current || !loaded) {
      return
    }

    let url = ''
    if (!tileId) {
      url = API.getTileUrl('')
    } else {
      url = API.getTileUrl(tileId)
    }

    const source = map.current.getSource('records') as VectorSourceImpl | undefined
    if (source?.url === url) {
      return
    }

    ky.get(url)
      .json()
      .then((data: any) => {
        if (fitBounds) {
          map.current?.fitBounds(data.bounds, {
            padding: padding ?? { top: 32, bottom: 32, left: 32, right: 16 + 29 + 10 },
            duration: 1500,
          })
        }
      })

    const id = tileId || 'records'

    addHeatmap(map.current, id)
    addRecords(map.current, id)
    if (matches && !isAdmin) {
      addRouting(map.current)
    }
  }, [tileId, loaded, fitBounds, padding, isAdmin])

  useEffect(() => {
    if (!map.current || !loaded) return

    const warning = quality?.min || 1
    const limit = quality?.max || 100
    for (const layer of LayerSpecs) {
      const colors = {
        low: '#000',
        moderate: '#ffa529',
        heavy: '#ff0505',
      }
      map.current.setPaintProperty(layer.id, 'line-color', [
        'interpolate',
        ['linear'],
        ['get', 'quality'],
        warning - 1,
        // 'hsl(0, 0%, 4%)',
        // 'hsl(213, 9%, 12%)',
        // 'transparent',
        colors.low,
        warning,
        colors.moderate,
        limit,
        colors.heavy,
      ])
    }
  }, [loaded, quality])

  return (
    <div ref={mapContainer} style={style}>
      {markerData.length ? <HidePannel map={map.current} markers={markers} /> : null}
      {markerData.length ? (
        <WaysPanel map={map.current} markers={markerData} geoJson={geoJson} />
      ) : null}
      {!isAdmin ? <Geocoder map={map.current} /> : null}
      {displayGraph ? (
        <div
          id="chart"
          className="blur"
          style={{
            position: 'absolute',
            bottom: 0,
            borderRadius: 6,
            left: '50%',
            transform: 'translate(-50%, 0)',

            backgroundColor: 'rgba(0, 0, 0, .52)',
            backdropFilter: 'blur(5px)',
            padding: 5,
          }}
        >
          <canvas id="myChart" width="1000" height="300"></canvas>
        </div>
      ) : null}
    </div>
  )
}
