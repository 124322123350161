/* eslint-disable max-lines */
import { useRef, useState } from 'react'

import {
  FeatureCollection,
  LineString,
  Position,
  featureCollection,
  lineSplit,
  lineString,
  nearestPointOnLine,
  point,
} from '@turf/turf'
import { GeoJSONSource, Map, Marker } from 'mapbox-gl'

import { End, Start, Step } from '../components/Markers'
import { config } from '../config'

function getMarker(lngLat: Position, icon: string, draggable = true) {
  const el = document.createElement('div')
  el.appendChild(new DOMParser().parseFromString(icon, 'image/svg+xml').documentElement)
  let selected = false
  el.addEventListener('mousedown', e => {
    selected = true
  })
  el.addEventListener('mousemove', e => {
    if (!selected && draggable) {
      e.stopPropagation()
    }
  })
  el.addEventListener('mouseup', () => {
    selected = false
  })
  if (!draggable) {
    el.style.cursor = 'pointer'
  }

  const marker = new Marker({
    draggable,
    element: el,
    pitchAlignment: 'viewport',
    clickTolerance: -1,
    offset: [0, -14],
  }).setLngLat([lngLat[0], lngLat[1]])

  return marker
}

export function useRouting() {
  const collection = useRef<FeatureCollection<LineString>>(featureCollection([]))
  const createdMarker = useRef<{
    marker: Marker
    waypoints: Position[]
    index: number
  } | null>(null)
  const currentDragging = useRef<{ moved: boolean } | null>(null)
  const preventDefault = useRef(false)
  const markers = useRef<Marker[]>([])
  const mapRef = useRef<Map>()
  const [markerData, setMarkerData] = useState<Marker[]>([])
  const [geoJson, setGeoJson] = useState<any>([])
  const controller = useRef<AbortController>()

  function getClosest(position: Position) {
    const line = lineString(
      collection.current.features
        .filter(f => f.geometry.type === 'LineString')
        .map(f => f.geometry.coordinates)
        .flat(),
    )

    return nearestPointOnLine(line, position).geometry.coordinates as [number, number]
  }

  function updateMarkers(coords: Position[]) {
    markers.current.forEach(marker => marker.remove())
    markers.current.length = 0
    coords.forEach((wp, i, arrWP) => {
      let icon = Step(i)
      if (!i) {
        icon = Start
      } else if (i === arrWP.length - 1) {
        icon = End
      }

      const marker = getMarker(wp, icon).addTo(mapRef.current!)

      marker.getElement().addEventListener('mousedown', () => {
        currentDragging.current = { moved: false }
      })
      marker.getElement().addEventListener('mousemove', () => {
        if (currentDragging.current && !currentDragging.current.moved) {
          currentDragging.current.moved = true
        }
      })
      marker.getElement().addEventListener('mouseup', () => {
        if (!currentDragging.current?.moved) {
          coords.splice(i, 1)
          marker.remove()
          route(coords)
        }
        currentDragging.current = null
      })

      marker.on('dragend', function () {
        currentDragging.current = null
        coords.splice(i, 1, marker.getLngLat().toArray())
        route(coords)
      })

      markers.current.push(marker)
    })

    setMarkerData([...markers.current])
  }

  function route(coords: Position[]) {
    const query = new URLSearchParams(coords.map(coord => ['coords', coord.join(',')]))

    if (coords.length < 2) {
      updateMarkers(coords)
      collection.current = featureCollection([])

      // Remove line
      const source = mapRef.current!.getSource('route') as GeoJSONSource
      if (source) {
        source.setData(collection.current)
      }
      return
    }

    if (controller.current) {
      controller.current.abort()
    }

    controller.current = new AbortController()

    fetch(`${config.baseUrl}route?${query.toString()}`, {
      signal: controller.current?.signal,
    })
      .then(res => res.json())
      .then(res => {
        const coords = res.waypoints.map(wp => wp.location)

        updateMarkers(coords)

        const cuttingWps = res.waypoints.slice(1, -1)
        const splitedLine = cuttingWps.reduce(
          (acc, wp, index) => {
            const { features } = lineSplit(acc.features.pop(), point(wp.location))

            features[0].properties!.index = index
            features[1].properties!.index = index + 1
            acc.features.push(...features)

            return acc
          },
          {
            features: [lineString(res.routes[0].geometry.coordinates, { index: 0 })],
          },
        )

        // const collection = featureCollection(splitedLine.features)

        collection.current = featureCollection([
          ...splitedLine.features,
          ...coords.map(c => point(c)),
        ])

        const source = mapRef.current!.getSource('route') as GeoJSONSource

        if (source) {
          source.setData(collection.current)
        }
      })
  }

  function addRouting(map: Map) {
    mapRef.current = map
    try {
      if (map.getSource('route')) {
        map.removeLayer('route')
        map.removeLayer('routePadding')
        map.removeSource('route')
      }
    } catch (e) {
      console.error(e)
    }

    // Routing
    map.addSource('route', {
      type: 'geojson',
      data: collection.current,
    })
    map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#28B883',
        'line-opacity-transition': {
          delay: 0,
          duration: 100,
        },
        // 'line-color': '#26C9EB',
        // 'line-color': '#03a9f4',
        'line-width': 5.5,
      },
    })

    map.addLayer({
      id: 'routePadding',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'transparent',
        'line-width': 23,
      },
    })
    const marker = getMarker([-90, -90], Step(null), false).addTo(map)

    map.on('mousemove', 'routePadding', async function (e) {
      if (createdMarker.current || currentDragging.current) {
        return
      }
      marker.setLngLat(getClosest(e.lngLat.toArray()))
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', 'routePadding', async function () {
      map.getCanvas().style.cursor = ''
      marker.setLngLat([-90, -90])
    })
    map.on('click', 'routePadding', () => {
      preventDefault.current = true
    })
    map.on('mousedown', 'routePadding', function (e) {
      e.originalEvent.cancelBubble = true
      e.preventDefault()
      e.originalEvent.stopPropagation()

      if (currentDragging.current) {
        return
      }

      marker.setLngLat([-90, -90])

      const waypoints: Position[] = []
      let index = -1

      const features = collection.current!.features.filter(
        f => f.geometry.type === 'LineString',
      )

      for (const feature of features) {
        waypoints.push(feature.geometry.coordinates[0])

        if (e.features?.[0].properties?.index === feature.properties?.index) {
          waypoints.push([e.lngLat.lng, e.lngLat.lat])
          index = waypoints.length - 1
        }
      }
      const lastFeature = features[features.length - 1]
      waypoints.push(lastFeature.geometry.coordinates.slice(-1)[0])

      const nMarker = getMarker(getClosest(e.lngLat.toArray()), Step(), false).addTo(map)
      markers.current.push(nMarker)

      createdMarker.current = {
        index,
        waypoints,
        marker: nMarker,
      }
    })
    map.on('mouseup', function () {
      if (createdMarker.current) {
        createdMarker.current.waypoints[createdMarker.current.index] =
          createdMarker.current.marker.getLngLat().toArray()
        route(createdMarker.current.waypoints)
        createdMarker.current = null
      }
    })
    map.on('mousemove', e => {
      if (createdMarker.current) {
        createdMarker.current.marker.setLngLat(e.lngLat)
      }
    })
    // map.on('contextmenu', function (e) {
    //   e.preventDefault()
    //   new Popup({ closeOnClick: false })
    //     .setLngLat(e.lngLat)
    //     .setHTML('<h1>Hello World!</h1>')
    //     .addTo(map)
    // })
    map.on('click', e => {
      if (preventDefault.current) {
        preventDefault.current = false
        return
      }
      const coords = markers.current.map(m => m.getLngLat().toArray())
      route([...coords, e.lngLat.toArray()])
    })
  }

  function addCoords(position: Position, index: number) {
    const coords = markers.current.map(m => m.getLngLat().toArray())
    coords.splice(index, 1, position)
    route(coords)
  }

  return {
    addRouting,
    markerData,
    markers: markers.current,
    geoJson: collection.current,
    addCoords,
  }
}
