import { useEffect, useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import { CircularProgress } from '@mui/material'

import { API } from '../helpers/API'

export function RequireAuth({ children }) {
  const location = useLocation()
  const user = API.isLogged()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    API.isLogged()
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
