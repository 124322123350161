import { Grid } from '@mui/material'

export function AdminContainer({ children, left = 0 }) {
  return (
    <Grid>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left,
          width: 450,
          maxHeight: '100%',
          overflow: 'auto',
          zIndex: 9,
        }}
      >
        {children}
      </div>
    </Grid>
  )
}
