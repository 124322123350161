import { useLocation, useNavigate } from 'react-router-dom'

import { Container, Box, Typography, TextField, Button } from '@mui/material'
import { HTTPError } from 'ky'

import { API } from '../helpers/API'

export function Login() {
  const navigate = useNavigate()
  const location = useLocation()

  const from = (location.state as any)?.from?.pathname || '/admin'

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    try {
      await API.signin({
        email: data.get('email')!.toString(),
        password: data.get('password')!.toString(),
      })
      navigate(from, { replace: true })
    } catch (e) {
      alert((e as HTTPError).response?.statusText || (e as Error).message)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={5}>
        <Typography component="h1" variant="h5" align="center">
          Admin
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            variant="outlined"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            variant="outlined"
            autoComplete="current-password"
          />
          <Box m={3}>
            <Button type="submit" fullWidth variant="contained">
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
