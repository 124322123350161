import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableRow,
  Typography,
} from '@mui/material'

export function TableInfo({ title = '', values, headers, onClick, noSelect = false }) {
  return (
    <Paper elevation={7}>
      <Box p={2}>
        {title ? (
          <>
            <Typography variant="h6">{title}</Typography>
            <Box mt={1} />
          </>
        ) : null}
        {values.length ? (
          <>
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {headers.map(h => (
                      <TableCell key={h.key}>{h.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values?.map((row, index) => (
                    <TableRow
                      key={row[headers[0].key] + index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover={!noSelect}
                      style={!noSelect ? { cursor: 'pointer' } : undefined}
                      onClick={() => onClick(row)}
                    >
                      {headers.map(h => (
                        <TableCell key={h.key}>{row[h.key]}</TableCell>
                      ))}
                      {/* <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </Box>
    </Paper>
  )
}
