// https://api.mapbox.com/geocoding/v5/mapbox.places/tou.json?country=fr%2Cch%2Cmc&access_token=pk.eyJ1IjoieW9ib3kiLCJhIjoiZXVCYjg5RSJ9.HujdU7ynB-74jYcQuMmgFg

import { useMemo, useEffect, useRef } from 'react'
import { useState } from 'react'

import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import { Marker, type Map } from 'mapbox-gl'

import { config } from '../config'

export function Geocoder({
  map,
  onValue,
}: {
  map?: Map
  onValue?: (position: any) => any
}) {
  const [value, setValue] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly any[]>([])
  const marker = useRef<Marker>()

  const get = useMemo(
    () =>
      debounce(async (input: string, callback: (results?: readonly any[]) => void) => {
        const searchParams = new URLSearchParams()
        searchParams.set('country', 'fr,ch,mc')
        searchParams.set('access_token', config.apiKey)

        const data = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&${searchParams.toString()}`,
        ).then(res => res.json())

        callback(
          data.features.map(f => ({
            id: f.id,
            line1: f.text,
            line2: f.context
              .filter(c => c.id.includes('region') || c.id.includes('country'))
              .map(c => c.text)
              .join(', '),
            bbox: f.bbox,
            center: f.center,
          })),
        )
      }, 400),
    [],
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    get(inputValue, results => {
      if (active) {
        let newOptions: any[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, get])

  return (
    <Paper
      sx={{
        position: 'absolute',
        zIndex: 9,
        top: 10,
        left: 10,
        userSelect: 'none',

        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Autocomplete
        freeSolo
        sx={{ width: 300 }}
        getOptionLabel={option => `${option.line1}, ${option.line2}`}
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText=""
        onChange={(event: any, newValue: any | null) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          onValue?.(newValue?.center)

          if (newValue) {
            if (map) {
              if (!marker.current) {
                marker.current = new Marker()
              }
              marker.current.setLngLat(newValue.center).addTo(map)
            }
            if (newValue.bbox) {
              map?.fitBounds(newValue.bbox, {
                duration: 3000,
                padding: 20,
                center: newValue.center,
              })
            } else {
              map?.flyTo({
                center: newValue.center,
                zoom: 12,
                duration: 3000,
              })
            }
          } else {
            marker.current?.remove()
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Rechercher un endroit..."
            size="small"
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {option.line1}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.line2}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
    </Paper>
  )
}
