import { JSX } from 'react'

import { Navigation as NavigationIcon, Flag as FlagIcon } from '@mui/icons-material'
import { Paper, Box, Typography, TextField, Button } from '@mui/material'
import { FeatureCollection } from '@turf/turf'
import { DateTime } from 'luxon'
import { Map, Marker } from 'mapbox-gl'
import togpx from 'togpx'

function Before({ index }: { index: number }) {
  let El: () => JSX.Element | null = () => null

  switch (index) {
    case 0:
      El = () => <NavigationIcon sx={{ height: 10, width: 10 }} />
      break
    case -1:
      El = () => <FlagIcon sx={{ height: 10, width: 10 }} />
      break
    default:
      El = () => <span>{index}</span>
      break
  }
  return (
    <Typography
      variant="body2"
      align="center"
      fontWeight="bold"
      style={{ userSelect: 'none' }}
    >
      <El />
    </Typography>
  )
}

export function HidePannel({ map, markers }: { map?: Map; markers: Marker[] }) {
  return (
    <Paper
      sx={{
        position: 'absolute',
        zIndex: 9,
        top: 10,
        left: '50%',
        transform: 'translate(-50%, 0)',
        padding: 2,
        userSelect: 'none',
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
      onMouseEnter={() => {
        map?.setPaintProperty('route', 'line-opacity', 0.1)
        // markers.forEach(m => {
        //   ;(m.getElement() as any).childNodes[0].style.opacity = 0.15
        // })
      }}
      onMouseLeave={() => {
        map?.setPaintProperty('route', 'line-opacity', 1)
        // markers.forEach(m => {
        //   ;(m.getElement() as any).childNodes[0].style.opacity = 1
        // })
      }}
    >
      <Typography variant="body2">Survoler pour cacher l'itinéraire</Typography>
    </Paper>
  )
}

export function WaysPanel({
  map,
  markers,
  geoJson,
}: {
  map?: Map
  markers: Marker[]
  geoJson: FeatureCollection
}) {
  return (
    <Paper
      sx={{
        position: 'absolute',
        zIndex: 9,
        bottom: 10,
        right: 10,
        padding: 2,
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          maxHeight: '390px',
          overflow: 'scroll',
        }}
      >
        <Box display="flex" flexDirection="column" mr={2}>
          {markers.map((m, i, arr) => {
            return <Before index={i && arr.length - 1 === i ? -1 : i} />
          })}
        </Box>
        <Box display="flex" flexDirection="column">
          {markers.map(m => {
            return (
              <Typography variant="body2">
                {m
                  .getLngLat()
                  .toArray()
                  .reverse()
                  .map(v => v.toFixed(4))
                  .join(', ')}
              </Typography>
            )
          })}
        </Box>
      </Box>
      <Button
        onClick={() => {
          const content = togpx(geoJson, { creator: '' })

          var element = document.createElement('a')
          element.setAttribute(
            'href',
            `data:application/gpx+xml;charset=utf-8,${encodeURIComponent(content)}`,
          )
          element.setAttribute('download', `${DateTime.now().toFormat('yyyy-MM-dd')}.gpx`)

          element.style.display = 'none'
          document.body.appendChild(element)

          element.click()

          document.body.removeChild(element)
        }}
        sx={{ marginTop: 2 }}
        size="small"
        variant="outlined"
        disableElevation
      >
        Exporter en gpx
      </Button>
    </Paper>
  )
}
