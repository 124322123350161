import { Dispatch, SetStateAction, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

import type { PaddingOptions } from 'mapbox-gl'

import { AdminGlobal } from '../components/AdminGlobal'
import { Map } from '../components/Map'

export type ContextType = {
  tileId?: string
  customQuality?: { min: number; max: number }
  padding?: PaddingOptions
  fitBounds?: boolean
}

export function useMapInfos() {
  return useOutletContext<{ setMapInfos: Dispatch<SetStateAction<ContextType>> }>()
}

export function Admin() {
  const [mapInfos, setMapInfos] = useState<ContextType>({})

  return (
    <>
      <Map
        isAdmin
        style={{ height: '100%' }}
        tileId={mapInfos.tileId}
        quality={mapInfos.customQuality}
        padding={
          mapInfos.padding || {
            top: 32,
            bottom: 32,
            left: 450 + 16,
            right: 16 + 29 + 10,
          }
        }
        fitBounds={mapInfos.fitBounds ?? true}
        onHover={console.log}
      />
      <AdminGlobal setMapInfos={setMapInfos} />
      <Outlet context={{ setMapInfos }} />
    </>
  )
}
