import { useMemo } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'

import { AdminUser } from './components/AdminUser'
import { RequireAuth } from './components/RequireAuth'
import { Admin } from './views/Admin'
import { Heatmap } from './views/Heatmap'
import { Home } from './views/Home'
import { Login } from './views/Login'

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          background: prefersDarkMode
            ? {
                default: '#1c2127',
                paper: '#00060d',
              }
            : {},
        },
      }),
    [prefersDarkMode],
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              }
            >
              <Route path=":tileId" element={<AdminUser />} />
            </Route>
            <Route path="/heatmap" element={<Heatmap />} />
            <Route index element={<Home />} />

            <Route path="*" element={<Navigate to="" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
